@media (max-width: 480px) {

    .heading {
        padding-bottom: 0;
    }
}

.searchbox {
    margin-top: 10px;
    width: 300px;
    margin-left: 30px;
    margin-bottom: 15px;
}

/*-------- Datepicker Design ---------*/
.datepickerWrapper {
    display: flex;
    align-items: center;
}

.datewrapper {
    position: relative;
    cursor: pointer;
}

.searchinput {
    border-radius: 25px;
    border: 1px solid #36aca3;
    padding: 5px 12px 4px 40px;
    position: relative;
    color: #36aca3;
    font-size: 14px;
    line-height: 21px;
    width: 140px;
    cursor: pointer;
    height: 40px;
    background-image: url("../../assets/img/calender.svg");
    background-repeat: no-repeat;
    background-position: 15px;
}
table{
  width: 90%;
}
.resetbutton{
    border: 1px solid #36aca3;
    color: #36aca3;
}
.tdata {
  width: 300px;  
}
tbody{
    width: 100% ;
}
.tbody{
  margin-left: 500px;
}
.arrow {
    margin: 0 8px;
}
td{
    color: black;
}
/* .uniqueName th,
td {
  border: 1px solid rgba(224, 224, 224, 1);
} */
@media (max-width: 340px) {
    .datepickerWrapper {
        display: inline-block;
        width: 100%;
    }
}
/* ::-webkit-scrollbar {
    width: 1px;
  }
  
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  ::-webkit-scrollbar-thumb {
    background: grey; 
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  } */


.details-modal {
    background: #ffffff;
    border-radius: 0.5em;
    box-shadow: 0 10px 20px rgba(black, 0.2);
    left: 50%;
    max-width: 90%;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30em;
    text-align: left;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
}
  
    .details-modal-close {
      align-items: center;
      color: #111827;
      display: flex;
      height: 4.5em;
      justify-content: center;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 4.5em;
    }
      svg {
        display: block;
      }
  
  
    .details-modal-title {
      color: #111827;
      padding: 1.5em 2em;
      pointer-events: all;
      position: relative;
      width: calc(100% - 4.5em);
    }
      h1 {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: normal;
      }
  
    .details-modal-content {
      border-top: 1px solid #e0e0e0;
      padding: 2em;
      pointer-events: all;
      overflow: auto;
    }
  
  
  .details-modal-overlay {
    transition: opacity 0.2s ease-out;
    pointer-events: none;
    background: rgba(#0f172a, 0.8);
    position: fixed;
    opacity: 0;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
  }  
    details[open] {
      pointer-events: all;
      opacity: 0.5;
    }
  
  